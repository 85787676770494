<template>
  <div id="header-one">
    <section
      class="bg-half-170 bg-light d-table w-100"
      style="background: url('images/real/build.png') bottom no-repeat"
    >
      <div class="container">
        <div class="row align-items-center mt-5">
          <div class="col-lg-7 col-md-6">
            <div class="title-heading">
              <span class="badge badge-pill badge-soft-primary"
                >Bienvenido COSTCO DEMO</span
              >
              <SmartLabel
                tag="h4"
                className="heading my-3"
                target="title"
                v-model="content.title"
              />
              <SmartLabel
                tag="p"
                className="para-desc text-muted"
                target="subtitle"
                v-model="content.subtitle"
              />
              <div class="mt-4 pt-2">
                <a href="javascript:void(0)" class="btn btn-primary m-1"
                  >Ver Planes</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="card shadow rounded border-0 ml-lg-4">
              <div class="card-body">
                <h5 class="card-title text-center">
                  Ingresa su código postal
                </h5>
                <form class="login-form mt-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Tipo de Seguro
                          <span class="text-danger"> *</span></label
                        >
                        <div class="position-relative">
                          <b-form-select
                            v-model="$v.form.selectedPackage.$model"
                            placehoder="Seleccione tipo de seguro"
                            :options="options"
                          />
                          <div
                            class="invalid-feedback"
                            v-bind:class="{
                              'd-block': $v.form.selectedPackage.$error,
                            }"
                          >
                            <span>Seleccione un tipo de seguro</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Ingrese código postal<span class="text-danger"
                            > *</span
                          ></label
                        >
                        <div class="position-relative">
                          <user-icon class="fea icon-sm icons"></user-icon>
                          <input
                            v-model="$v.form.postalCode.$model"
                            type="number"
                            class="form-control pl-5"
                            placeholder="Ingrese código postal"
                            name="postalCode"
                          />
                          <div
                            class="invalid-feedback"
                            v-bind:class="{
                              'd-block': $v.form.postalCode.$error,
                            }"
                          >
                            <span>Proporcione un valor válido</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="$v.form.agree.$model"
                            type="checkbox"
                            class="custom-control-input"
                            id="agree"
                          />

                          <label class="custom-control-label" for="agree"
                            >Acepto
                            <a href="#" class="text-primary"
                              >politicas de tratamiendo de mis datos</a
                            ></label
                          >
                          <div
                            class="invalid-feedback"
                            v-bind:class="{
                              'd-block': $v.form.agree.$error,
                            }"
                          >
                            <span>Debes aceptas las politicas de tratamiento de datos</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center col-md-12">
                      <b-button
                        @click="nextStep()"
                        class="btn btn-md bg-primary btn-primary col-md-12"
                        >Obtener Cotización
                      </b-button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { UserIcon, MailIcon } from "vue-feather-icons";
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";

const isTrue = (value) => value === true;

export default {
  mixins: [validationMixin],
  data() {
    return {
      options: [
        { value: "PROPETARIO", text: "Seguro propietario" },
        { value: "ARREDATARIO", text: "Seguro arrendatario" },
        { value: "ARRENDADOR", text: "Seguro arrendador" },
      ],
      form: {
        postalCode: "",
        selectedPackage: "",
        agree: false,
      },
    };
  },

  validations: {
    form: {
      postalCode: { required, numeric },
      selectedPackage: { required },
      agree: { isTrue },
    },
  },

  name: "HeaderOne",
  components: {
    UserIcon,
    MailIcon,
  },

  methods: {
    nextStep() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      this.$router.push({
        name: "Insured",
        query: {
          postalCode: this.form.postalCode,
          selectedPackage: this.form.selectedPackage,
        },
      });
    },
  },
};
</script>